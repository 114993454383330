<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="mi-orderview">
		<div class="mi-uc-box">
			<div class="box-hd">
				<h1 class="title">
					订单详情
				</h1>
				<div class="more clearfix">
					<h2 class="subtitle">
						订单号：{{order.OrderNo}}
						<!---->
					</h2>
					<div class="actions">
						<!-- <div data-v-c436733c="" class="status-btn-box"><a data-v-c436733c=""
								href="javascript:void(0)"
								class="btn btn-small btn-line-gray btn-contract">联系客服</a><a
								data-v-ceab8a26="" data-v-c436733c="" title="取消订单"
								class="btn btn-small btn-line-gray">取消订单</a><a data-v-323978a0=""
								data-v-c436733c="" title="立即付款"
								class="btn btn-small btn-primary">立即付款</a></div> -->
					</div>
					<div class="clearfix"></div>
				</div>
			</div>
			<div class="box-bd">
				<div class="order-detail uc-order-item-pay">
					<div data-v-10ff82cc="" class="order-summary">
						<!---->
						<div data-v-10ff82cc="" class="order-status">
							<div data-v-10ff82cc="" style="display: inline-block;">{{order.State==0?"等待付款":""}}</div>
							<!---->
							<!---->
							<!---->
							<!---->
							<!---->
							<!---->
							<!---->
						</div>
						<!---->

						<!---->
					</div>
					<!---->
					<div class="order-goods">
						<table class="goods-table">
							<tr data-v-c355d0c8="" class="goods-box" v-for="(item,index) in goodlist" :key="index">
								<td data-v-c355d0c8="" class="col col-thumb">
									<div data-v-c355d0c8="" class="figure figure-thumb"><a data-v-c355d0c8=""
											target="_blank" href="">
											<img
												data-v-c355d0c8="" :src="getUrl(item.GoodsImg)" width="80" height="80" alt=""></a></div>
								</td>
								<td data-v-c355d0c8="" colspan="3" class="col col-info">
									<table data-v-c355d0c8="" class="col-info-box">
										<tr data-v-c355d0c8="" class="goods-box-info"   >
											<td data-v-c355d0c8="" class="col col-name">
												<div data-v-c355d0c8="" class="name">
													<a data-v-c355d0c8=""
														target="_blank"
														href="">
														{{item.GoodsName}}
													</a>
													<!---->
													<!---->
													<!---->
													<!---->
													<!---->
												</div>
											</td>
											<td data-v-c355d0c8="" class="col col-price">
												<p data-v-c355d0c8="" class="price">{{item.Price}}元 × {{item.Num}}</p>
											</td>
											<td data-v-c355d0c8="" class="col col-actions">
												<!---->
												<!---->
												<!---->
												<!---->
												<!---->
												<!---->
												<!---->
												<!---->
												<!---->
											</td>
										</tr>
										<!---->
									</table>
								</td>
							</tr>
							<!---->
							<!---->
						</table>
					</div>
				</div>
				<!---->
				<div class="order-detail-info">
					<h3>收货信息</h3>
					<table class="info-table" v-for="(item,index) in addresslist" :key="index">
						<tbody>
							<tr>
								<th>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</th>
								<td>{{item.Consignee}}</td>
							</tr>
							<tr>
								<th>联系电话：</th>
								<td>{{item.Mobile}}</td>
							</tr>
							<tr>
								<th>收货地址：</th>
								<td>{{item.Address}}</td>
							</tr>
							<tr>
								<th>配送方式：</th>
								<td>快递配送</td>
							</tr>						
						</tbody>
					</table>
					<!-- <div class="actions"><a class="btn btn-small btn-line-gray">修改</a>
						
					</div> -->
				</div>
				<div data-v-942b74ea="" class="order-detail-info">
					<h3 data-v-942b74ea="">支付方式
						<!---->
					</h3>
					<table data-v-942b74ea="" class="info-table">
						<tbody data-v-942b74ea="">
							<tr data-v-942b74ea="">
								<th data-v-942b74ea="">支付方式：</th>
								<td data-v-942b74ea="">易票联支付</td>
							</tr>
							<!---->
							<!---->
						</tbody>
					</table>
					<div data-v-942b74ea="" class="actions"></div>
				</div>
				<div data-v-620f8a49="" class="order-detail-total">
					<table data-v-620f8a49="" class="total-table">
						<tbody data-v-620f8a49="">
							<!-- <tr data-v-620f8a49="">
								<th data-v-620f8a49="">商品总价：</th>
								<td data-v-620f8a49=""><span data-v-620f8a49=""
										class="num">569.00</span>元</td>
							</tr> -->
							<!---->
							<!---->
							<!---->
							<!-- <tr data-v-620f8a49="">
								<th data-v-620f8a49="">运费：</th>
								<td data-v-620f8a49=""><span data-v-620f8a49=""
										class="num">0</span>元</td>
							</tr> -->
							<!---->
							<!---->
							<!---->
							<tr data-v-620f8a49="">
								<th data-v-620f8a49="" class="total">商品总价：</th>
								<td data-v-620f8a49="" class="total"><span data-v-620f8a49="" class="num">{{order.Total}}</span>元
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	export default {
		name: 'Order',
		data() {
			return {
				id: 0,
				order: {
					OrderNo: '',
					State: 0,
					Total:0
				},
				addresslist: [],
				goodlist: []
			};
		},
		// activated() {
		//   // 获取订单数据
		//   this.$axios
		//     .post("/user/order/getOrder", {
		//       user_id: this.$store.getters.getUser.user_id
		//     })
		//     .then(res => {
		//       if (res.data.code === "001") {
		//         this.orders = res.data.orders;
		//       } else {
		//         this.notifyError(res.data.msg);
		//       }
		//     })
		//     .catch(err => {
		//       return Promise.reject(err);
		//     });
		// },
		watch: {
			// 通过订单信息，计算出每个订单的商品数量及总价
			// orders: function(val) {
			//   let total = [];
			//   for (let i = 0; i < val.length; i++) {
			//     const element = val[i];

			//     let totalNum = 0;
			//     let totalPrice = 0;
			//     for (let j = 0; j < element.length; j++) {
			//       const temp = element[j];
			//       totalNum += temp.product_num;
			//       totalPrice += temp.product_price * temp.product_num;
			//     }
			//     total.push({ totalNum, totalPrice });
			//   }
			//   this.total = total;
			// }
		},
		created() {
			var option = this.$route.query
			if (option.id) {
				this.id = option.id
				this.loadInfo()
			}


		},
		methods: {
			async loadInfo() {
				const res = await this.postdata('/Order/OrderDetail', {
					queryId: this.id
				})
				if (res.code == 200) {
					this.order = res.data.order
					this.addresslist = res.data.llist
					this.goodlist = res.data.dlist
				}
			}


		}
	};
</script>
<style scoped>
	/* 订单为空的时候显示的内容CSS */
	.clearfix {
		clear: both;
	}

	.mi-uc-box {
		padding: 36px 48px;
		background: #fff
	}

	.mi-uc-box .tag {
		display: inline-block;
		padding: 0 12px;
		margin-left: 10px;
		margin-top: -2px;
		font-size: 12px;
		color: #fff;
		vertical-align: middle
	}

	.mi-uc-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.mi-uc-box .box-hd small {
		margin-left: 10px;
		font-size: 12px;
		line-height: 1.5
	}

	.mi-uc-box .box-hd small a {
		color: #757575
	}

	.mi-uc-box .box-hd small a:hover {
		text-decoration: underline
	}

	.mi-uc-box .box-hd .more {
		border-bottom: 1px solid #e0e0e0
	}

	.mi-uc-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}

	.mi-uc-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}

	.mi-uc-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.mi-uc-box .box-hd .filter-list li.active,
	.mi-uc-box .box-hd .filter-list li.active a {
		color: #ff6700
	}

	.mi-uc-box .box-hd .subtitle {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #333;
		padding: 0.83em 0;

	}

	.mi-uc-box .box-hd .actions {
		float: right;
		padding-top: 5px
	}

	.mi-uc-box .box-hd .actions .btn {
		margin-left: 5px
	}

	.mi-uc-box .box-bd .empty {
		margin: 40px 0;
		font-size: 18px;
		text-align: center;
		color: #b0b0b0
	}

	.mi-uc-box .box-bd .empty p {
		margin: 0 0 5px
	}

	.mi-uc-box .box-bd .empty a {
		font-size: 14px;
		color: #424242
	}

	.mi-uc-box .box-bd .empty a:hover {
		color: #ff6700
	}

	.mi-uc-box .box-bd .empty .sep {
		font-size: 14px
	}

	.mi-orderview .tag-subsidy {
		background-color: #2196f3
	}

	.mi-orderview .tag-package {
		background-color: #83c44e
	}

	.mi-orderview .tag-vno-activate {
		background-color: #2196f3
	}

	.mi-orderview .uc-order-item-shipping .order-desc,
	.mi-orderview .uc-order-item-shipping .order-status {
		color: #83c44e
	}

	.mi-orderview .uc-order-item-finish .order-desc,
	.mi-orderview .uc-order-item-finish .order-status {
		color: #b0b0b0
	}

	.mi-orderview .uc-order-item-pay .order-desc,
	.mi-orderview .uc-order-item-pay .order-status {
		color: #ff6700;
		margin-bottom: 16px;
	}

	.mi-orderview .order-detail {
		padding: 25px 0;
		border-bottom: 1px solid #e0e0e0;
	}

	.mi-orderview .order-detail-info {
		position: relative;
		height: auto;
		min-height: 120px;
		padding: 12px 0 12px;
		margin-bottom: 16px;
		border-bottom: 1px solid #e0e0e0
	}

	.mi-orderview .order-detail-info h3 {
		margin: 0 0 15px;
		font-size: 18px;
		font-weight: 400;
		color: #333
	}

	.mi-orderview .order-detail-info .info-table {
		color: #757575
	}

	.mi-orderview .order-detail-info .info-table td,
	.mi-orderview .order-detail-info .info-table th {
		padding: 3px 0;
		text-align: left
	}

	.mi-orderview .order-detail-info .info-table th {
		padding-right: .3em
	}

	.mi-orderview .order-detail-info .info-table p {
		margin: 0
	}

	.mi-orderview .order-detail-info .actions {
		position: absolute;
		top: 5px;
		right: 0
	}

	.mi-orderview .order-detail-info .actions .btn {
		display: block;
		margin: 0 0 10px
	}

	.total-table[data-v-620f8a49] {
		margin: 0 0 0 auto;
		color: #757575
	}

	.total-table td[data-v-620f8a49],
	.total-table th[data-v-620f8a49] {
		padding: 5px 0;
		text-align: left
	}

	.total-table td.total[data-v-620f8a49],
	.total-table th.total[data-v-620f8a49] {
		padding-top: 25px;
		vertical-align: text-bottom
	}

	.total-table td.total .num[data-v-620f8a49],
	.total-table th.total .num[data-v-620f8a49] {
		font-size: 30px;
		font-weight: 200;
		line-height: 1
	}

	.total-table th[data-v-620f8a49] {
		width: 200px;
		font-weight: 400;
		text-align: right
	}

	.total-table td[data-v-620f8a49] {
		text-align: right;
		color: #ff6700
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #ff6700;
		border-color: #ff6700;
		color: #fff;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	.goods-table {
		width: 100%
	}

	.goods-table .goods-box {
		border-bottom: 1px solid #e0e0e0;
		padding-top: 10px
	}

	.goods-table .col {
		text-align: left;
		color: #333
	}

	.goods-table .col-thumb {
		width: 100px
	}

	.goods-table .col-name {
		width: 350px
	}

	.goods-table .col-name a {
		color: #333
	}

	.goods-table .col-name a:hover {
		color: #ff6700
	}

	.goods-table .col-actions {
		width: 120px;
		padding: 0;
		text-align: right
	}

	.goods-table .col-offset {
		padding-left: 20px
	}

	.goods-table .figure-thumb {
		width: 80px
	}

	.goods-table .figure-thumb a {
		display: block
	}

	.goods-table .figure-thumb img {
		width: 80px;
		height: 80px
	}

	.goods-table .name {
		margin: 0
	}

	.goods-table .name a {
		color: #333
	}

	.goods-table .name a:hover {
		color: #ff6700
	}

	.goods-table .name .contract_info {
		display: block;
		color: #757575;
		font-size: 12px
	}

	.goods-table .name .contract_info i {
		display: inline-block;
		font-size: 12px;
		margin-right: 3px
	}

	.goods-table .price {
		margin: 0
	}

	.goods-table .view_glasses_info {
		cursor: pointer
	}
</style>
